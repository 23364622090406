import React, { useState } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import HTMLReactParser from 'html-react-parser';
import Slider from "react-slick";
import Img01 from "../../../images/propertyDetails/map.jpg"
import Img02 from "../../../images/propertyDetails/map02.jpg"
import "./PropertyDescription.scss";
import ScrollAnimation from 'react-animate-on-scroll';
// Header component

const Description = (props) => {

    const [readMore, setReadMore] = useState(props.propertyData.long_description.length >= 600)

    return (
        <React.Fragment>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                <section className="description" id="property-details-description">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className="content-wrapper">
                                    <Row>
                                        <div className="heading col-lg-3">
                                            {
                                                props.propertyData.long_description.length > 0 &&
                                                <h2 className="hidden">property
                                                    <span className="sm-text">description
                                                    </span></h2>
                                            }
                                        </div>
                                        <div className="col-lg-9 description-text">
                                            <div className={`details-info property-description-details ${readMore ? "read_more" : "read_less"}`}>

                                                {props.propertyData.long_description &&
                                               
                                                     <div className={`expand ${readMore ? "panel-closed" : ""}`}>
                                                        {readMore ?
                                                            <>{HTMLReactParser(props.propertyData.long_description.substr(0, 600))}</>
                                                            :
                                                            <>{HTMLReactParser(props.propertyData.long_description)}</>
                                                        }
                                                    
                                                        </div>
                                               
                                                }
                                                {props.propertyData.long_description.length >= 600 &&
                                                    <a href="javascript:void(0)" className="readmore_expand" onClick={()=>{setReadMore(!readMore)}}>{readMore ? "Read more" : "Read Less"}</a>
                                                }

                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </ScrollAnimation>
        </React.Fragment>
    );
};

export default Description;
